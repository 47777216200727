.gallery-site {
  overflow-y: hidden;
  overflow-x: auto;
  max-width: 100%;
}
.gallery-site--title {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}
.gallery-site--container {
  display: flex;
  width: fit-content;
  gap: 1rem;
  padding-bottom: 0.5rem;
  overflow-y: hidden;
  overflow-x: auto;
  scroll-behavior: smooth;
  user-select: none;
}

.img-site {
  position: relative;
  padding: 0.2rem;
  height: 10rem;
  aspect-ratio: 1 / 1;
  cursor: pointer;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
}
.img-site::before {
  transition: opacity 0.2s;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  background: var(--color-1t);
  backdrop-filter: blur(2px);
}
.img-site:hover::before {
  opacity: 1;
}
.img-site img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/*-------------------*/
/* MEDIA QUERY */
/*-------------------*/
/* MOBILE */
@media screen and (max-width: 900px) {
  .gallery-site {
    grid-column: 1;
    /* grid-row: 2; */
    gap: 0.5rem;
  }
  .img-site {
    height: 6rem;
    min-width: 8rem;
  }
}
