.card-section {
  position: absolute;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-template-rows: 1fr;
  column-gap: 3rem;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: var(--color-2);
}
.card-section--section1 {
  padding: 1.5rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.card-title-container {
  margin-bottom: 2rem;
}
.card-title-container__title {
  font-size: 2.5rem;
  text-transform: uppercase;
  line-height: 2rem;
}
.card-title-container__subtitle {
  font-size: 1.2rem;
  opacity: 0.8;
}
.card-section--section2 {
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  margin-left: auto;
  overflow-y: auto;
  overflow-x: hidden;
}

/* -------------------  media queries 800px ------------------- */
@media screen and (max-width: 800px) {
  .card-section {
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
