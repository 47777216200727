.menu-card-container {
  position: sticky;
  top: 3rem;
  left: 0;
  display: flex;
  align-items: center;
  height: 2.5rem;
  max-width: 100%;
  color: var(--color-2);
  background: var(--color-1);
  overflow-x: auto;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
  z-index: 1;
}
.menu-card-container::-webkit-scrollbar {
  display: none;
}
.menu-card-container a {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
  color: var(--color-2);
  cursor: pointer;
  text-decoration: none;
}
.menu-card-container .icon-card-list {
  color: var(--color-3);
}
.menu-active-item {
  background: var(--color-2);
  color: var(--color-1) !important;
}
.menu-active-item .icon-card-list {
  color: var(--color-1) !important;
}

/*------------------------------*/
/*--------MEDIA QUERYES--------*/
/*------------------------------*/
/*
-----PC
*/

@media screen and (min-width: 800px) {
  .menu-card-container {
    gap: 1rem;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
    top: 6rem;
    margin: 3rem 0;
    padding: 0 3rem;
  }
  .menu-card-container a {
    padding: 0rem 1rem;
    border-bottom: 1px solid transparent;
  }
  .menu-active-item {
    background: none;
    color: var(--color-2) !important;
    border-bottom: 1px solid var(--color-2) !important;
  }
  .menu-active-item .icon-card-list {
    color: var(--color-2) !important;
  }
}
