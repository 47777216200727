.banner-site {
  position: relative;
  height: calc(100vh - 3rem);
  overflow: hidden;
  background: var(--color-6);
}

/*-------------------*/
/* MEDIA QUERY */
/*-------------------*/

/* MOBILE */
@media screen and (max-height: 30rem) {
  .banner-site {
    overflow-y: auto;
  }
}
