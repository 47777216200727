.place-link {
  color: var(--color-2);
}

.place-box {
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  cursor: pointer;
  color: var(--color-2);
  background-color: var(--color-1);
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
}

.profile-place {
  transition: transform 0.3s;
  width: 100%;
  height: 100%;
}
.profile-place:hover {
  transform: rotate(1deg) scale(1.05);
}

.data-box {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1rem;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-image: linear-gradient(0deg, black, transparent);
}
.place-box .tag-place {
  position: absolute;
  padding: 0.5rem 1rem;
  top: 1rem;
  left: 0;
  text-transform: capitalize;
  background: var(--color-1);
}
.data-box .title-place {
  text-transform: uppercase;
  font-size: 1.1rem;
}
.data-box .description-place {
  font-size: 0.9rem;
  opacity: 0.8;
}
