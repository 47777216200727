.menu {
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  height: 3rem;
  top: 0;
  left: 0;
  background: var(--color-1);
  color: var(--color-2);
  z-index: 2;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.logo a {
  transition: 0.2s;
  color: var(--color-2);
  text-decoration: none;
  font-size: 1.5rem;
}
.logo a:hover {
  color: var(--color-3);
}
.burguer-menu {
  display: none;
}
.menu-title {
  display: none;
  font-weight: normal;
}
.menu-list {
  display: flex;
  overflow: hidden;
}
.menu-list a {
  color: var(--color-2);
  text-decoration: none;
}
.menu-item {
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
  text-transform: uppercase;
}
.menu-item-icon,
.menu-item-icon-down {
  transition: 0.5s;
  color: var(--color-3);
}
/*
====================================MEDIA QUERYES
-------DESKTOP
*/
@media screen and (min-width: 50rem) {
  .menu-list {
    gap: 1rem;
    height: 3rem;
  }
  .menu-item {
    align-items: center;
    transition: 0.2s;
    height: 100%;
  }
  .menu-item:hover {
    color: var(--color-3);
  }
  .menu-item:hover .menu-item-icon-down {
    transform: rotate(-180deg);
  }
  .dropdown {
    transition: 0.2s;
    position: absolute;
    opacity: 0;
    pointer-events: none;
    flex-direction: column;
    top: 3rem;
    max-height: calc(100vh - 4rem);
    border-left: 0.15rem solid var(--color-3);
    background: var(--color-1);
    transform: translateY(1rem);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .dropdown a {
    text-decoration: none;
  }
  .dropdown .menu-item {
    position: relative;
    color: var(--color-2);
    text-decoration: none;
    padding: 1rem;
  }
  .dropdown .menu-item::before {
    position: absolute;
    content: "";
    width: 0.3rem;
    height: 0.1rem;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: var(--color-3);
  }
  .menu-item:hover .dropdown {
    display: flex;
    opacity: 1;
    pointer-events: all;
    transform: none;
  }

  .dropdown .menu-item:hover {
    color: var(--color-3);
  }
}
/*
-------MOVIL
*/
@media screen and (max-width: 50rem) {
  .burguer-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 2rem;
    height: 1.5rem;
    cursor: pointer;
  }
  .burguer-bar {
    transition: 0.5s;
    height: 0.1rem;
    background: var(--color-2);
    border-radius: 0rem;
    pointer-events: none;
  }
  .burguer-bar:nth-child(1) {
    width: 70%;
    transform-origin: left;
  }
  .burguer-bar:nth-child(2) {
    width: 60%;
    margin: auto;
  }
  .burguer-bar:nth-child(3) {
    width: 70%;
    margin-left: auto;
    transform-origin: right;
  }
  .menu-container {
    transition: 0.7s cubic-bezier(1, 0.01, 1, 1);
    transition-delay: 0.3s;
    position: fixed;
    padding: 1rem;
    width: 100vw;
    top: 3rem;
    left: 0;
    bottom: 0;
    background: var(--color-1);
    transform: translateX(100%);
  }
  .menu-active .menu-container {
    transition: 0.6s cubic-bezier(0, 0.99, 1, 1);
    transition-delay: 0s;
    overflow-y: auto;
  }
  .menu-title {
    transition: 1s;
    display: block;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    margin-left: 0.3rem;
    opacity: 0;
    color: var(--color-2);
  }
  .menu-container .menu-list {
    display: flex;
  }
  .menu-active .burguer-bar {
    background: var(--color-3);
  }
  .menu-active .burguer-bar:nth-child(1) {
    transform: rotate(45deg) translateX(0.2rem);
  }
  .menu-active .burguer-bar:nth-child(2) {
    width: 80%;
    transform: rotate(-45deg);
  }
  .menu-active .burguer-bar:nth-child(3) {
    transform: rotate(45deg) translateX(-0.2rem);
  }
  .menu-active .menu-container {
    transform: none;
  }
  .menu-active .menu-title {
    opacity: 0.6;
  }
  .menu-container .menu-list {
    flex-direction: column;
  }

  .menu-container .menu-item {
    padding: 0.7rem 0;
  }
  .menu-container .menu-item--anim {
    transition: 0.5s cubic-bezier(1, 0.01, 1, 1);
    transform: translateX(100%);
    opacity: 0;
  }
  .menu-active .menu-item--anim {
    opacity: 1;
    transform: none;
    transition: 0.8s cubic-bezier(1, 0.01, 1, 1);
  }

  .menu-container .menu-item--anim:nth-child(1) {
    transition-delay: 0.4s;
  }
  .menu-container .menu-item--anim:nth-child(2) {
    transition-delay: 0.3s;
  }
  .menu-container .menu-item--anim:nth-child(3) {
    transition-delay: 0.2s;
  }
  .menu-container .menu-item--anim:nth-child(4) {
    transition-delay: 0s;
  }
  .menu-active .menu-item--anim:nth-child(1) {
    transition-delay: 0s;
  }
  .menu-active .menu-item--anim:nth-child(2) {
    transition-delay: 0.1s;
  }
  .menu-active .menu-item--anim:nth-child(3) {
    transition-delay: 0.2s;
  }
  .menu-active .menu-item--anim:nth-child(4) {
    transition-delay: 0.3s;
  }

  .menu-list .menu-item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0 0.5rem;
  }
  .menu-list .menu-item-icon {
    width: 1.5rem;
    color: var(--color-3);
  }
  .menu-list span {
    min-width: 7rem;
  }
  .menu-list .menu-item-icon-down {
    transition: 0.5s;
    color: var(--color-3);
  }

  /*
  DROPDOWNS
  */
  .dropdown {
    transition: 0.3s;
    transition-delay: 0.2s;
    padding: 0 1rem;
    margin-left: 1rem;
    width: 100%;
    height: 0;
    opacity: 0;
    overflow: hidden;
    border-left: thin solid var(--color-3);
  }
  .dropdown a {
    text-decoration: none;
    color: var(--color-2);
  }
  .dropdown li {
    position: relative;
    transition: 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .menu-item--active .dropdown {
    margin-top: 1rem;
    opacity: 1;
    height: 100%;
  }
  .menu-item--active .dropdown li::before {
    position: absolute;
    content: "";
    top: 50%;
    transform: translateY(-50%);
    left: -1rem;
    width: 0.2rem;
    height: 0.1rem;
    background: var(--color-3);
  }
  .menu-item--active > span {
    color: var(--color-3);
  }
  .menu-item--active .menu-item-icon-down {
    transform: rotate(180deg);
  }
}

/*
================================================ANIMATIONS
*/
