.details-card {
  display: flex;
  column-gap: 1.5rem;
  row-gap: 0.5rem;
  flex-wrap: wrap;
  align-items: center;
  height: fit-content;
  font-size: 1.1rem;
}
.details-card--icon {
  color: var(--color-3);
}
.details-card__container {
  width: fit-content;
  height: fit-content;
}
.details-card__title {
  display: flex;
  font-size: 1rem;
  gap: 0.3rem;
  flex-wrap: wrap;
}
.details-card__text {
  font-size: 1rem;
  text-transform: uppercase;
  opacity: 0.7;
}
.details-card-text {
  font-size: 1.1rem;
  padding-right: 0.5rem;
  overflow-y: auto;
  overflow-x: hidden;
}
.details-card-text__title {
  font-size: 1.1rem;
  font-weight: normal;
}
.details-card-text__paragraph {
  opacity: 0.7;
  font-weight: 200;
  margin: 1rem 0;
  max-width: 30rem;
}
.details-card-reservation {
  margin-top: 1.5rem;
}
.details-card__reservation-options {
  display: flex;
  padding-top: 0.5rem;
  gap: 1rem;
}
.contact-option {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.7rem;
  width: 8rem;
  text-decoration: none;
  border: 0.15rem solid transparent;
  color: var(--color-2);
  background: var(--color-1);
  border-radius: 0.45rem;
}
.contact-option:hover {
  text-decoration: underline;
}
.contact-option--gm:hover {
  border: 0.15rem solid var(--color-5);
}
.contact-option--wp:hover {
  border: 0.15rem solid var(--color-3);
}
.contact-option--icon {
  font-size: 1.2em;
}
.contact-option--gm .contact-option--icon {
  color: var(--color-5);
}
.contact-option--wp .contact-option--icon {
  color: var(--color-3);
}

/* -------------------  media queries 800px ------------------- */
@media screen and (max-width: 800px) {
  .details-card-text__paragraph {
    max-width: 100%;
  }
}
