.photo-viewer {
  transition: opacity 0.4s;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  background: var(--color-1t);
  backdrop-filter: blur(3px);
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  z-index: 2;
}
.photo-viewer--active {
  opacity: 1;
  pointer-events: all;
}
.close-modal {
  transition: 0.2s;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  width: 2rem;
  height: 2rem;
  top: 1rem;
  right: 1rem;
  color: var(--color-2);
  background: var(--color-1);
  cursor: pointer;
}
.modal-buttons {
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.buttons__btn {
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 3rem;
  color: var(--color-2);
  background: var(--color-1);
  cursor: pointer;
}
.buttons__btn *,
.close-modal * {
  pointer-events: none;
}
.buttons_btn--hide {
  opacity: 0;
  pointer-events: none;
}
.img-target {
  width: 100%;
  height: 100%;
  padding: 1rem;
}
.img-target--hide {
  opacity: 0;
}
.img-target img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/*----------------------*/
/* MEDIA QUERYES */
/*----------------------*/
/* MOBILE */
@media screen and (max-width: 450px) {
  .img-target {
    padding: 0rem;
  }
}
